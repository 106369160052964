































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import dayjs from 'dayjs'

import {
  Account,
  Payee,
  Investment,
  AccountTransaction,
  TransactionStatus,
  TransactionCategory,
  Membership,
  MemberPreference
} from '@/models'

import AppAccountTransactionsTable from '@/components/AppAccountTransactionsTable'
import AccountReconcileToolbar from './components/AccountReconcileToolbar.vue'
import CashFlowSidebar from './components/CashFlowSidebar.vue'

Vue.component('AppAccountTransactionsTable', AppAccountTransactionsTable)
Vue.component('AccountReconcileToolbar', AccountReconcileToolbar)
Vue.component('CashFlowSidebar', CashFlowSidebar)

const Auth = namespace('auth')

@Component
export default class CashFlow extends Vue {
  @Auth.Getter currentPermissions!: string
  @Auth.Getter currentMemberId!: string

  loading = true
  loadingTransactions = true
  triggeredClearAll = false

  accounts: Account[] = []
  investments: Investment[] = []
  transactionStatuses: TransactionStatus[] = []
  transactionCategories: TransactionCategory[] = []
  payees: Payee[] = []
  draftTypes = [
    'check',
    'cash',
    'credit_card',
    'pay_online',
    'autodraft',
    'transfer',
    'ach',
    'wire',
    'adjustment'
  ]

  accountParam: string | null = null
  payeeParam: Account | Payee | null = null
  investmentParam: string | null = null
  draftTypeParam: string | null = null
  recurringParam: boolean | null = null
  estimateParam: boolean | null = null
  transactionCategoryParam: string | null = null
  amountParam: string | number | null = null
  transactionStatusParam: (string | undefined)[] = ['1', '2']
  transactionDateParam: (string | null)[] | null = [
    null,
    dayjs().add(1, 'month').format('YYYY-MM-DD')
  ]

  accountSidebarItems: Account[] = []

  permissions = {
    enableCreate: false,
    enableReadAccount: false,
    enableReadAccountTransaction: false,
    enableReadInvestment: false,
    enableUpdate: false,
    enableDestroy: false
  }

  currentMemberPreference = new MemberPreference()
  accountTransactionsTableKey = 0
  tableColumnNames = [
    'recurring',
    'transactionDate',
    'accountName',
    'payeeName',
    'transactionCategory',
    'inflow',
    'outflow',
    'isEstimate',
    'equity',
    'transactionStatusName',
    'draftType',
    'notes',
    'actionButtons'
  ]

  get hiddenColumns () {
    if (this.currentMemberPreference.accountTransactionsTableColumns.length === 0) return []

    return this.tableColumnNames.filter(tc => {
      return !this.currentMemberPreference.accountTransactionsTableColumns.includes(tc)
    })
  }

  async mounted () {
    this.getCurrentPermissions()
    await Promise.all([
      this.getMemberPreferences(),
      this.getTransactionStatuses(),
      this.getTransactionCategories(),
      this.getAccounts(),
      this.getPayees(),
      this.getInvestments()
    ])
    this.loading = false
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.permissions.enableCreate = permissions.includes('create_account_transaction')
    this.permissions.enableReadAccount = permissions.includes('read_account')
    this.permissions.enableReadAccountTransaction = permissions.includes('read_account_transaction')
    this.permissions.enableReadInvestment = permissions.includes('read_investment')
    this.permissions.enableUpdate = permissions.includes('update_account_transaction')
    this.permissions.enableDestroy = permissions.includes('destroy_account_transaction')
  }

  async getMemberPreferences () {
    const currentMember = (await Membership
      .includes('memberPreference')
      .find(this.currentMemberId)
    ).data
    this.currentMemberPreference = currentMember.memberPreference
  }

  async getAccounts () {
    if (!this.permissions.enableReadAccount) return

    this.accounts = (await Account
      .includes('defaultInvestment')
      .order({ active: 'desc' })
      .order('name')
      .per(1000)
      .all()
    ).data

    this.accounts = [
      new Account({ id: null, name: 'All Accounts', active: 1 })
    ].concat(this.accounts.map(item => { return item }))

    this.accountSidebarItems = this.insertActiveExitedSeparator(JSON.parse(JSON.stringify(this.accounts)))
  }

  async getPayees () {
    if (!this.permissions.enableReadAccountTransaction) return
    this.payees = (await Payee.order('name').per(10000).all()).data
  }

  async getInvestments () {
    if (!this.permissions.enableReadInvestment) return
    this.investments = (await Investment.order('name').per(1000).all()).data
  }

  async getTransactionStatuses () {
    this.transactionStatuses = (await TransactionStatus.per(1000).all()).data
  }

  async getTransactionCategories () {
    this.transactionCategories = (await TransactionCategory.per(1000).order('name').all()).data
  }

  async onMemberPreferencesChanged () {
    await this.getMemberPreferences()
    this.reloadTable()
  }

  reloadTable () {
    Vue.nextTick(() => {
      this.accountTransactionsTableKey += 1
    })
  }

  onParamChanged (param: any) {
    const key = param[0]
    const value = param[1]
    if (!key && !value) {
      this.resetParams()
    } else {
      (this as any)[key] = value
    }
  }

  resetParams () {
    this.triggeredClearAll = true

    this.payeeParam = null
    this.transactionCategoryParam = null
    this.amountParam = null
    this.recurringParam = null
    this.estimateParam = null
    this.investmentParam = null
    this.draftTypeParam = null

    Vue.nextTick(() => {
      this.triggeredClearAll = false
    })
  }

  insertActiveExitedSeparator (accounts: Account[]) {
    let idx = -1
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i]
      if (!account.active) {
        idx = i
        break
      }
    }
    if (idx >= 0) {
      accounts.splice(idx, 0, new Account({
        name: 'Closed Accounts',
        active: true
      }))
    }
    return accounts
  }

  async onAccountTransactionsRefreshed (triggerCalendarRefresh: boolean) {
    if (this.$refs && this.$refs.reconcile_toolbar) {
      (this.$refs.reconcile_toolbar as any).refreshAccountBalances()
    }

    let cashFlowSidebar: any
    if (this.$refs && this.$refs.cashflow_sidebar) {
      cashFlowSidebar = this.$refs.cashflow_sidebar
    }

    if (triggerCalendarRefresh && cashFlowSidebar) {
      await cashFlowSidebar.getTransactionDates()
    }

    if (cashFlowSidebar) {
      cashFlowSidebar.checkToDisableCalendarNavigationBasedOnFilterDates()
    }
    this.loadingTransactions = false
  }

  onAccountReconciled () {
    this.triggerTransactionRefresh()
  }

  onBooksVerified () {
    this.triggerTransactionRefresh()
  }

  triggerTransactionRefresh () {
    if (this.$refs && this.$refs.account_transactions_table) {
      (this.$refs.account_transactions_table as any).getAccountTransactions()
    }
  }
}
